.side {
	height: 100vh;
	background: #fff;
	display: flex;
	flex-direction: column;
	width: 190px;
	transition: all 0.3s;
	z-index: 100;
	position: relative;
	box-shadow: 0px 0px 21px -12px #000;

	&__logo {
		height: 70px;
		display: flex;
		justify-content: center;

		&_min {
			height: 64px !important;
			padding-top: 5px;
		}

		img {
			max-width: 100%;
			width: 90%;
		}
	}

	&__mobile {
		position: fixed;
		right: -200px;
		top: 0;
    visibility: hidden;
    transition: .3s;
    // opacity: 0;

    &.mobile-show {
      right: 0;
      // opacity: 1;
      visibility: visible;
    }
	}

	&__close {
		width: 70px;

		.side__item a span,
		.side__btns_toggle-nav span,
		.side__btns_logout span {
			display: none;
		}

		.side__item a svg,
		.side__btns_logout svg,
		.side__btns_toggle-nav svg {
			margin-left: 0px;
			margin-right: 0px;
		}

		.side__item a,
		.side__btns button {
			justify-content: center;
		}
	}

	&__items {
		list-style: none;
		flex-grow: 1;
		margin-top: 10px;
		padding: 0 7px;
	}

	&__item {
		a {
			color: $dark-color;
			text-decoration: none;
			padding: 13px 15px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid $white-color;
			transition: all 0.3s;
			white-space: nowrap;
			background: rgba($dark-color, 0.2);
			margin: 5px 0;
			border-radius: 8px;

			svg {
				font-size: 25px;
				margin-left: 7px;
			}

			&:hover {
				background: rgba($dark-color, 0.4);
				// color: $white-color;
			}
		}

		&-active {
			a {
				color: $white-color;
				background: rgba($dark-color, 0.8);

				&:hover {
					background: rgba($dark-color, 0.8);
				}
			}
		}
	}

	&__btns {
		padding-bottom: 14px;
		box-shadow: 0 -2px 3px -1px #ccc;

		&_logout {
			background: none;
			color: $red-color;
			width: 100%;
			text-align: right;
			font-size: 17px;
			border: none;
			cursor: pointer;
			padding: 10px 15px;
			transition: all 0.3s;
			display: flex;
			align-items: center;
			white-space: nowrap;

			svg {
				font-size: 25px;
				margin-left: 7px;
			}

			&:hover {
				background: rgba($red-color, 0.2);
			}
		}

		&_toggle-nav {
			background: #ddd;
			color: $dark-color;
			width: 100%;
			text-align: right;
			font-size: 17px;
			border: none;
			cursor: pointer;
			padding: 10px 15px;
			transition: all 0.3s;
			display: flex;
			align-items: center;
			white-space: nowrap;
			justify-content: flex-end;

			svg {
				font-size: 25px;
				margin-left: 7px;
			}

			&:hover {
				background: #b3b3b3;
			}
		}

		button {
			&:focus {
				outline: none;
			}
		}
	}

	/* width */
	::-webkit-scrollbar {
		width: 5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #888;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}
