
.helplinks {
    list-style: none;

    li {

        a {
            // color: #000;
            font-family: "Vazir", sans-serif;
            text-decoration: none;
            font-size: 20px;
            padding: 10px 0;
            display: block;
        }
    }
}