.appointments-page {
	&__form {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.text-input {
			width: 200px;
			margin-bottom: 0;
		}

		&_btn {
			border: none;
			background: $primary-color;
			color: $white-color;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 5px;
			cursor: pointer;
			border-radius: 5px;
			transition: 0.3s;

			&:hover {
				background: $primary-color-dark;
			}

			svg {
				font-size: 30px;
			}
		}
	}

	&__colors {
		list-style: none;
		display: flex;
		flex-wrap: wrap;

		li {
			margin-right: 35px;
			// margin-bottom: 5px;
			padding-right: 5px;
			position: relative;

			&:first-child {
				margin-right: 20px;
			}

			&::before {
				content: "";
				position: absolute;
				left: 100%;
				top: 4px;
				width: 16px;
				height: 16px;
				border-radius: 20px;
				// border: 1px solid #000;
				box-shadow: 0 0 5px 0 #ccc;
			}

			&.green {
				color: #52b69ac0;

				&::before {
					background: #52b69ac0;
				}
			}

			&.yellow {
				color: #e8c14bc5;

				&::before {
					background: #e8c14bc5;
				}
			}

			&.white {

				&::before {
					background: #fff;
				}
			}
		}
	}
}

.appointments {
	// margin-top: 30px;
	height: 100%;

	&__title {
		font-size: 21px;
		margin-left: 15px;
		font-weight: 700;
	}

	&__grid {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: stretch;
		height: 100%;

		&_col {
			display: flex;
			flex-direction: column;
			width: 100%;
			width: 49%;
		}

		&_col-head {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-bottom: 15px;

			// flex-direction: column;
		}
	}

	&__box {
		box-shadow: 0 0 20px -5px #ccc;
		border-radius: 10px;
		padding: 20px 10px;
		height: 100%;
		flex: 1;

		&_list {
			list-style: none;

			li {
				display: flex;
				align-items: center;
				padding: 10px;
				margin-bottom: 10px;
				border-radius: 10px;
				box-shadow: 0 0 20px -5px #ccc;

				&:last-child {
					margin-bottom: 0;
				}

				div {
					width: 50%;
					display: flex;
					align-items: center;
					font-size: 20px;

					svg {
						font-size: 26px;
						margin-left: 6px;
					}
				}

				&.green {
					background: #52b69ac0;
				}

				&.yellow {
					background: #e8c14bc5;
				}

				&.white {
					background: #fff;
				}
			}
		}
	}
}
