.dantal-step-fhor {
	display: flex;
	justify-content: center;
}

.charts {
	width: 348px;
	display: flex;
	flex-direction: column;

	&__row {
		display: flex;
		width: 100%;

		&-1 {
			flex-direction: row-reverse;
		}
	}

	&__col {
		width: 50%;
		height: 212px;
		position: relative;
	}

	&__one {
		border-right: 1px solid;
		border-bottom: 1px solid;
	}

	&__tow {
		border-bottom: 1px solid;
	}

	&__fhor {
		border-right: 1px solid;
	}
}

.chart {
	&__item {
		position: absolute;
		width: 35px;
		height: 35px;
		border: 1px solid;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		cursor: pointer;

		&.green-filter {
			background: #296630;
			color: #fff;
			border-color: #296630 ;
		}

		&.red-filter {
			background: red;
			color: #fff;
		}
	}

	&__item-one {
		&-11 {
			right: 6px;
			top: 0;
			font-family: dentis2;
		}

		&-12 {
			right: 49px;
			top: 0;
			span {

				font-family: dentis2;
			}
			
		}

		&-13 {
			right: 93px;
			top: 0;
		}

		&-14 {
			right: 136px;
			top: 0;
		}

		&-15 {
			right: 136px;
			top: 43px;
		}

		&-16 {
			right: 136px;
			top: 86px;
		}

		&-17 {
			right: 136px;
			top: 129px;
		}

		&-18 {
			right: 136px;
			top: 172px;
		}
	}

	&__item-tow {
		&-21 {
			left: 6px;
			top: 0;
		}

		&-22 {
			left: 49px;
			top: 0;
		}

		&-23 {
			left: 93px;
			top: 0;
		}

		&-24 {
			left: 136px;
			top: 0;
		}

		&-25 {
			left: 136px;
			top: 43px;
		}

		&-26 {
			left: 136px;
			top: 86px;
		}

		&-27 {
			left: 136px;
			top: 129px;
		}

		&-28 {
			left: 136px;
			top: 172px;
		}
	}

	&__item-three {
		&-31 {
			left: 6px;
			bottom: 0;
		}

		&-32 {
			left: 49px;
			bottom: 0;
		}

		&-33 {
			left: 93px;
			bottom: 0;
		}

		&-34 {
			left: 136px;
			bottom: 0;
		}

		&-35 {
			left: 136px;
			bottom: 43px;
		}

		&-36 {
			left: 136px;
			bottom: 86px;
		}

		&-37 {
			left: 136px;
			bottom: 129px;
		}

		&-38 {
			left: 136px;
			bottom: 172px;
		}
	}

	&__item-fhor {
		&-41 {
			right: 6px;
			bottom: 0;
		}

		&-42 {
			right: 49px;
			bottom: 0;
		}

		&-43 {
			right: 93px;
			bottom: 0;
		}

		&-44 {
			right: 136px;
			bottom: 0;
		}

		&-45 {
			right: 136px;
			bottom: 43px;
		}

		&-46 {
			right: 136px;
			bottom: 86px;
		}

		&-47 {
			right: 136px;
			bottom: 129px;
		}

		&-48 {
			right: 136px;
			bottom: 172px;
		}
	}

	&__child {
		&_item-one {
			&-51 {
				bottom: 114px;
				right: 6px;
				width: 30px;
				height: 30px;
			}

			&-52 {
				bottom: 114px;
				right: 43px;
				width: 30px;
				height: 30px;
			}

			&-53 {
				bottom: 77px;
				right: 43px;
				width: 30px;
				height: 30px;
			}

			&-54 {
				bottom: 40px;
				right: 43px;
				width: 30px;
				height: 30px;
			}

			&-55 {
				bottom: 3px;
				right: 43px;
				width: 30px;
				height: 30px;
			}
		}

		&_item-tow {
			&-61 {
				bottom: 114px;
				left: 6px;
				width: 30px;
				height: 30px;
			}

			&-62 {
				bottom: 114px;
				left: 43px;
				width: 30px;
				height: 30px;
			}

			&-63 {
				bottom: 77px;
				left: 43px;
				width: 30px;
				height: 30px;
			}

			&-64 {
				bottom: 40px;
				left: 43px;
				width: 30px;
				height: 30px;
			}

			&-65 {
				bottom: 3px;
				left: 43px;
				width: 30px;
				height: 30px;
			}
		}

		&_item-three {
			&-71 {
				top: 114px;
				left: 6px;
				width: 30px;
				height: 30px;
			}

			&-72 {
				top: 114px;
				left: 43px;
				width: 30px;
				height: 30px;
			}

			&-73 {
				top: 77px;
				left: 43px;
				width: 30px;
				height: 30px;
			}

			&-74 {
				top: 40px;
				left: 43px;
				width: 30px;
				height: 30px;
			}

			&-75 {
				top: 3px;
				left: 43px;
				width: 30px;
				height: 30px;
			}
		}

		&_item-fhor {
			&-81 {
				top: 114px;
				right: 6px;
				width: 30px;
				height: 30px;
			}

			&-82 {
				top: 114px;
				right: 43px;
				width: 30px;
				height: 30px;
			}

			&-83 {
				top: 77px;
				right: 43px;
				width: 30px;
				height: 30px;
			}

			&-84 {
				top: 40px;
				right: 43px;
				width: 30px;
				height: 30px;
			}

			&-85 {
				top: 3px;
				right: 43px;
				width: 30px;
				height: 30px;
			}
		}

	
	}

	
}

.dental-step-form {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 30px;

	.text-input {
		width: 33.3333%;
	}
}

.dental-chart {
	&__user-form {
		display: flex;
		flex-wrap: wrap;

		.text-input {
			width: 25%;
		}
	}

	&__charts {
		direction: ltr;
		width: max-content;
		margin: 30px auto;
		border: solid 1px #2b3d51;
		padding: 11px;

		&_images {
			display: flex;

			&__item {
				position: relative;
				border: 1px solid $primary-color;
				margin: 2px;
				cursor: pointer;
			}

			&__notif {
				position: absolute;
				width: 300px;
				right: -122px;
				top: 105%;
				margin: auto;
				background: #fff;
				padding: 10px 0;
				border: 1px solid #ddd;
				box-shadow: 0 0 16px 0px #ccc;
				z-index: 200;
				opacity: 0;
				visibility: hidden;
				transition: 0.3s;
				border-radius: 5px;
				// position: relative;

				&.show {
					visibility: visible;
					opacity: 1;
					top: 94%;
				}

				div {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					padding: 5px 0;
					border-bottom: 1px solid #ccc;

					&:last-child {
						border-bottom: none;
					}

					p {
						padding: 0 4px;
						font-size: 13px;
					}
				}

				&::before {
					content: "";
					width: 0;
					height: 0;
					border-width: 10px 0 12px 10px;
					border-color: transparent transparent transparent #fff;
					position: absolute;
					top: -15px;
					right: 0;
					left: 0;
					margin: auto;
					z-index: 200;
					border-style: solid;
					transform: rotate(-90deg);
				}
			}
		}

		&_row1 {
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
		}

		&_row2 {
			display: flex;
			justify-content: center;
		}

		&_row3 {
			display: flex;
			justify-content: center;
			align-items: center;

			&__col-1 {
				margin-right: -14px;
			}

			&__col-2 {
				position: relative;
			}

			&__col-3 {
				margin-left: -14px;
			}
		}

		&_row4 {
			display: flex;
			justify-content: center;
		}

		&_row5 {
			display: flex;
			justify-content: space-between;
			margin-top: 10px;
		}

		&_ul {
			margin-left: 4px;
		}

		&_ur {
			margin-right: 4px;
			direction: rtl;
		}

		&_ll {
			margin-left: 4px;
		}

		&_lr {
			margin-right: 4px;
			direction: rtl;
		}

		&_numbers {
			list-style: none;
			display: flex;

			&__item {
				width: 60px;
				text-align: center;
				margin: 2px 3px;
				padding: 5px 0;
				font-weight: 600;
			}
		}

		&_all-btn {
			position: relative;
			padding: 3px 6px;
			font-weight: 500;
			background: #fff;
			border: solid 2px $primary-color;
			z-index: 2;
			border-radius: 5px;
			color: $primary-color;
			cursor: pointer;
			transition: 0.3s;

			&:hover {
				color: $white-color;
				background: $primary-color;
			}

			&::before {
				content: "";
				width: 2px;
				height: 100px;
				background: $primary-color;
				position: absolute;
				top: 28px;
				left: 0;
				right: 0;
				margin: auto;
			}

			&::after {
				content: "";
				width: 2px;
				height: 100px;
				background: $primary-color;
				position: absolute;
				bottom: 28px;
				left: 0;
				right: 0;
				margin: auto;
			}
		}

		&_button {
			&__btn {
				padding: 3px 6px;
				font-weight: 500;
				background: #fff;
				border: solid 2px $primary-color;
				z-index: 2;
				border-radius: 5px;
				color: $primary-color;
				cursor: pointer;
				transition: 0.3s;

				&.active {
					background: $primary-color;
					color: #fff;
				}

				&:hover {
					color: $white-color;
					background: $primary-color;
				}
			}

			&_input {
				width: 50px;
				padding: 3px;
				border-radius: 5px;
				margin: 0 5px;
				border: solid 2px $primary-color;

				&:focus {
					outline: none;
				}
			}
		}
	}

	&__options-form {
		margin-top: 20px;

		display: flex;
		flex-wrap: wrap;

		.text-input {
			width: 25%;
		}
	}

	&__final {
		.table {
			margin-bottom: 20px;
		}
	}
}

.dental-chart-page {
	.MuiStepLabel-root {
		flex-direction: column !important;
	}
	.MuiStepLabel-iconContainer {
		padding-right: 0 !important;
	}

	&__btn-container {

		.MuiButton-containedPrimary {

			padding: 6px 40px !important;
			background-color: #296630 !important;
		}
	}
}

.red-filter {
	filter: brightness(0.87) contrast(1.11) hue-rotate(270deg) saturate(5);
}

.green-filter {
	img {
		filter: brightness(1) hue-rotate(45deg) saturate(10);
	}
}

.css-ascpo7-MuiStepLabel-root {
	flex-direction: column;
	text-align: center;
}

.css-vnkopk-MuiStepLabel-iconContainer {
	padding-right: 0;
	margin-bottom: 9px;
}

.css-16ubnlw-MuiStepLabel-labelContainer span,
.MuiButton-root,
.css-dwuj3p-MuiTableCell-root,
.css-1yhpg23-MuiTableCell-root {
	font-family: "Vazir", sans-serif !important;
}

.step-panel {
	padding: 40px 0px 0px 0px;
}

.css-1fw5qro-MuiPaper-root {
	box-shadow: 0 0 6px 0 #ccc !important;
}

.css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar {
	direction: ltr;
}

.css-1yhpg23-MuiTableCell-root {
	padding: 12px 16px !important;
}

.MuiStepLabel-label {
    font-family: "Vazir",sans-serif !important;
}