body {
	direction: rtl;
	font-family: "Vazir", sans-serif;
}

button,
input,
p,
a,
textarea {
	font-family: "Vazir", sans-serif;
}

* {
	padding: 0;
	margin: 0;
}

.ltr {
	direction: ltr;
}

.overflow {
	overflow: auto;
	height: 100%;
	padding: 1px;
}

.loader-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100vh;
	background: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  right: 0;
  
	&__box {
		display: flex;
		flex-direction: column;
		align-items: center;
    justify-content: center;
		background: #fff;
		border-radius: 15px;
		overflow: hidden;
		padding: 15px;
    width: 180px;
    height: 180px;
	}

	img {
		width: 80px;
	}
}

.main-loading {
	position: fixed;
	top: 0;
	width: 100%;
	background: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(2px);
	z-index: 999;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 110px;
	}
}

.swal-text {
	text-align: right !important;
}

.hLCrav .rnc-button svg {
	fill: #000 !important;
}

.hLCrav .rnc-column {
	margin: 0 10px 0 0px !important;
}

.overflow-y {
	overflow-y: auto;
}

.overflow-x {
	overflow-x: auto;
}
