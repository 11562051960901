@media (max-width: 1300px) {
	.dental-chart__charts_images__item img {
		width: 47px;
	}

	.dental-chart__charts {
		width: auto;
	}

	.dental-chart__charts_numbers__item {
		width: 47px;
	}
}

@media (max-width: 1200px) {
	.appointments {
		&__grid {
			flex-direction: column;

			&_col {
				width: 100%;

				&:first-child {
					margin-bottom: 30px;
				}
			}
		}
	}
}

@media (max-width: 1100px) {
	.dental-chart__charts_images__item img {
		width: 40px;
	}

	.dental-chart__charts_numbers__item {
		width: 40px;
	}
}

@media (max-width: 992px) {
	.visit-page__form .text-input,
	.report-filter__form .text-input {
		width: 33.3333%;
	}

	.button {
		width: 170px;
	}

	.user-info__form .text-input {
		width: 33.3333%;
	}

	.user-visit__form .text-input {
		width: 33.3333%;

		&.w-custom {
			width: 100% !important;
		}
	}

	.user-visit__lists {
		flex-direction: column;

		&_1,
		&_2,
		&_3 {
			width: 100%;
			margin-bottom: 15px;
		}

		&_text {
			width: -webkit-fill-available;
			justify-content: space-between;
			border: 1px solid #eee;
			margin: 8px 0;
			align-items: center;
			border-radius: 4px;
			padding: 0 5px;
		}
	}

	.dental-chart {
		&__charts {
			&_row2,
			&_row4 {
				flex-direction: column;
				align-items: center;
			}

			&_row3 {
				&__col-3 {
					display: none;
				}

				&__col-1 {
					display: none;
				}

				&__col-2 {
					button {
						width: 150px;
					}
				}
			}

			&_ur {
				margin-right: 0;
			}

			&_ul {
				margin-left: 0;
			}

			&_lr {
				margin-right: 0;
			}

			&_ll {
				margin-left: 0;
			}
		}
	}
}

@media (max-width: 768px) {
	.visit-page__form .text-input,
	.report-filter__form .text-input {
		width: 50%;
	}

	// .button {
	//     width: 30%;
	// }

	.change-password__form .text-input {
		width: 60%;
	}

	.change-password__form .button {
		width: 50%;
	}

	.user-info__form .text-input {
		width: 50%;
	}

	.user-visit__form .text-input {
		width: 50%;
	}

	.header__toggle-menu {
		display: flex;
	}

	.side__btns {
		display: none;
	}

	.side__items {
		padding-bottom: 10px;
	}

	.appointments {
		&__box {
			&_list {
				li {
					div {
						font-size: 18px;

						svg {
							font-size: 22px;
						}
					}
				}
			}
		}
	}

	.appointments-page__colors {
		flex-direction: column;

		li {
			margin-right: 20px;
		}
	}

	.dental-step-form .text-input {
		width: 50%;
	}
}

@media (max-width: 550px) {
	.visit-page__form .text-input,
	.report-filter__form .text-input {
		width: 100%;
	}
	.report-page {
		overflow-x: hidden;
		margin: 30px 15px;
	}

	.change-password__form .text-input {
		width: 100%;
	}

	.profile-page {
		margin: 30px 15px;
	}

	.change-password__form .button {
		width: 85%;
	}

	.user-info__form .text-input {
		width: 100%;
	}

	.user-visit__form .text-input {
		width: 100%;

		&.w-custom {
			width: 100% !important;
		}
	}

	.dental-step-form .text-input {
		width: 50%;
	}

	.step-panel {
		padding: 18px 0px 0px 0px;
	}

	.chart__item {
		width: 31px;
		height: 31px;
	}

	.charts__col {
		height: 182px;
	}

	.chart__item {
		&-one {
			&-11 {
				right: 3px;
			}

			&-12 {
				right: 38px;
			}

			&-13 {
				right: 73px;
				top: 0;
			}

			&-14 {
				right: 108px;
				top: 0;
			}

			&-15 {
				right: 108px;
				top: 35px;
			}

			&-16 {
				right: 108px;
				top: 70px;
			}

			&-17 {
				right: 108px;
				top: 105px;
			}

			&-18 {
				right: 108px;
				top: 140px;
			}
		}

		&-tow {
			&-21 {
				left: 3px;
			}

			&-22 {
				left: 38px;
			}

			&-23 {
				left: 73px;
			}

			&-24 {
				left: 108px;
			}

			&-25 {
				left: 108px;
				top: 35px;
			}

			&-26 {
				left: 108px;
				top: 70px;
			}

			&-27 {
				left: 108px;
				top: 105px;
			}

			&-28 {
				left: 108px;
				top: 140px;
			}
		}

		&-fhor {
			&-41 {
				right: 3px;
				bottom: 0;
			}

			&-42 {
				right: 38px;
				bottom: 0;
			}

			&-43 {
				right: 73px;
			}

			&-44 {
				right: 108px;
				bottom: 0;
			}

			&-45 {
				right: 108px;
				bottom: 35px;
			}

			&-46 {
				right: 108px;
				bottom: 70px;
			}

			&-47 {
				right: 108px;
				bottom: 105px;
			}

			&-48 {
				right: 108px;
				bottom: 140px;
			}
		}

		&-three {
			&-31 {
				left: 3px;
				bottom: 0;
			}

			&-32 {
				left: 38px;
				bottom: 0;
			}

			&-33 {
				left: 73px;
			}

			&-34 {
				left: 108px;
				bottom: 0;
			}

			&-35 {
				left: 108px;
				bottom: 35px;
			}

			&-36 {
				left: 108px;
				bottom: 70px;
			}

			&-37 {
				left: 108px;
				bottom: 105px;
			}

			&-38 {
				left: 108px;
				bottom: 140px;
			}
		}
	}

	.chart {
		&__child_item {
			&-one {
				&-55 {
					right: 38px;
				}
				&-54 {
					right: 38px;
					bottom: 38px;
				}
				&-53 {
					right: 38px;
					bottom: 73px;
				}
				&-52 {
					right: 38px;
					bottom: 108px;
				}
				&-51 {
					right: 3px;
					bottom: 108px;
				}
			}

			&-tow {
				&-65 {
					left: 38px;
				}
				&-64 {
					left: 38px;
					bottom: 38px;
				}
				&-63 {
					left: 38px;
					bottom: 73px;
				}
				&-62 {
					left: 38px;
					bottom: 108px;
				}
				&-61 {
					left: 3px;
					bottom: 108px;
				}
			}

			&-three {
				&-75 {
					left: 38px;
				}
				&-74 {
					left: 38px;
					top: 38px;
				}
				&-73 {
					left: 38px;
					top: 73px;
				}
				&-72 {
					left: 38px;
					top: 108px;
				}
				&-71 {
					left: 3px;
					top: 108px;
				}
			}

			&-fhor {
				&-85 {
					right: 38px;
				}
				&-84 {
					right: 38px;
					top: 38px;
				}
				&-83 {
					right: 38px;
					top: 73px;
				}
				&-82 {
					right: 38px;
					top: 108px;
				}
				&-81 {
					right: 3px;
					top: 108px;
				}
			}
		}
	}

	.header {
		height: 60px;

		&__grid {
			display: flex;
			justify-content: space-between;
			padding: 0 10px;
		}
	}
}

@media (max-width: 450px) {
	.visit-page {
		padding: 25px 10px;
	}

	// .button {
	//     width: 50%;
	// }

	.login-page__box {
		width: 86%;
		padding: 30px 15px;
	}

	.dental-chart__charts_images__item img {
		width: 35px;
	}

	.dental-chart__charts_numbers__item {
		width: 35px;
		font-size: 13px;
	}

	.panel-page__paper {
		margin: 10px;
		padding: 20px 10px;
	}

	.appointments {
		&__box {
			&_list {
				li {
					font-size: 17px;
					flex-direction: column;
					align-items: flex-start;
					div {
						width: 100%;
					}
				}
			}
		}
	}
}

@media (max-width: 390px) {
	.dental-chart__charts_images__item img {
		width: 32px;
	}

	.dental-chart__charts_numbers__item {
		width: 32px;
	}
}

@media (max-width: 350px) {
	.dental-chart-page__btn-container {
		padding-top: 0 !important;
	}
}

@media (min-width: 992px) {
	.dental-chart {
		&__charts {
			&_row2,
			&_row4 {
				.dental-chart__charts_numbers {
					display: none;
				}
			}
		}
	}
}
