.modal {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(3px);
	visibility: hidden;
	opacity: 0;
	// transform: scale(0);
	transition: all 0.3s;
	z-index: 109;

	&__box {
		background: #fff;
		width: 90%;
		margin: 40px auto;
		height: 90vh;
		border-radius: 0;
		// overflow-y: auto;
	}

	&__head {
		display: flex;
		justify-content: space-between;
		padding: 10px 20px;
		border-bottom: 1px solid #ddd;
		align-items: center;

		&_title {
			font-size: 17px;
			font-weight: 500;
		}

		&_close {
			font-size: 24px;
			padding: 7px;
			background: none;
			border: none;
			display: flex;
		}
	}

	&__body {
		padding: 20px;
		height: 82%;
	}

	&__show {
		opacity: 1;
		visibility: visible;
		// transform: scale(1);
	}
}

.fast-visit-modal .modal__box {
  max-width: 500px;

	.modal__body {
		overflow-y: auto;
		background: #fff;
	}

	.user-visit__lists {
		flex-direction: column;

		&_text {
			width: -webkit-fill-available;
			justify-content: space-between;
			border: 1px solid #eee;
			margin: 8px 0;
			align-items: center;
			border-radius: 4px;
			padding: 10px;
		}

		&_item {
      height: auto;
			padding: 5px;

      &__date {
        font-weight: 500;
      }

      &__cost {
        font-size: 14px;
      }

      &__num {
        font-size: 13px;
      }

      button {
        width: auto;
        font-size: 14px;
      }

    }
      &_info {
			display: flex;
			flex-direction: column;
		}

		&_btn {
			// width: 100%;
			padding: 20px;
		}

	}
}
