::-webkit-input-placeholder {
  /* Edge */
  text-align: right;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  text-align: right;
}

::placeholder {
  text-align: right;
}
