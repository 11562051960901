.report-page {
	overflow-x: hidden;
	margin: 30px;
}

.panel-page {
	overflow: hidden;

	&__grid {
		display: flex;
	}

	&__pages {
		width: 100%;
		height: 100vh;
		overflow-y: auto;
		min-width: 270px;
		background: #eaecee;
		overflow-x: hidden;
		flex: 1 1;
		display: flex;
		flex-direction: column;
	}

	&__main {
		width: 100%;
		overflow-x: hidden;
		display: flex;
		flex-direction: column;
		justify-content: stretch;
		height: 100vh;
	}

	&__paper {
		padding: 30px 15px;
		margin: 20px;
		background: $white-color;
		flex: 1;
		display: flex;
		flex-direction: column;

		&_list {
			margin-top: 30px;

			&__title {
				margin-bottom: 10px;
			}
		}
	}
}

.panel-head {
	background: #ffffff;
	// height: 65px;
	color: #000;
	display: flex;
	align-items: center;
	padding: 15px 20px;
	font-size: 16px;
	justify-content: space-between;

	&__full-width {
		position: relative;
		width: max-content;
		float: left;
		&-btn {
			background: none;
			font-size: 30px;
			line-height: 0;
			border: none;
			cursor: pointer;
		}
		&_tooltip {
			position: absolute;
			top: 1px;
			left: 20px;
			font-size: 14px;
			background: rgba(0, 0, 0, 0.5);
			color: #fff;
			display: block;
			padding: 2px 5px;
			border-radius: 5px;
			visibility: hidden;
			opacity: 0;
			transition: all 0.3s;
		}

		&:hover &_tooltip {
			visibility: visible;
			opacity: 1;
			left: 120%;
		}
	}
}

.report-filter {
	&__form {
		display: flex;
		flex-wrap: wrap;

		.text-input {
			width: 25%;
		}
	}
}

.mini-loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;

	img {
		width: 50px;
	}
}
