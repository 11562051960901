.accordion {
  margin-bottom: 30px;

  &__tabs {
    border-radius: 8px;
    overflow: hidden;
  }

  &__tab {
    width: 100%;
    color: white;
    overflow: hidden;

    &_label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: #eee;
      font-weight: bold;
      border: 1px solid #eee;
      cursor: pointer;
      position: relative;
      padding-right: 18px;
      color: #000;
      padding-left: 0 !important;
      border-radius: 10px;


      & svg {
        text-align: center;
        transition: all 0.3s;
        position: absolute;
        left: 11px;
        font-size: 16px;
        margin-top: 0px;
      }

      &__code {
        font-size: 17px;
      }
    }
    &_content {
      max-height: 0;
      padding: 0;
      transition: all 0.3s;
      overflow: hidden;
      color: #000;
      padding: 0 1em;
      border-radius: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-color: #eee;

      p {
        padding-bottom: 10px;
        display: block;
        font: 16px yekan;
        font-weight: 500;
        color: #fafafa;
      }

      strong {
        padding-bottom: 15px;
        display: block;
        font: 19px yekan;
        font-weight: 600;
        color: #fafafa;
      }
    }

    &_input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
  }
}

// :checked
.accordion__tab_input:checked {
  + .accordion__tab_label {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    & svg {
      transform: rotate(-180deg);
    }
  }
  ~ .accordion__tab_content {
    max-height: 100vh;
    padding: 1em;
    border: 1px solid #eee;
  }
}
