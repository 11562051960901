.profile-page {
  &__nav {
    overflow-x: auto;

    &_items {
      display: flex;
      list-style: none;
      box-shadow: inset 0 -2px 0 0 #eee;
      min-width: max-content;
    }

    &_item {
      a {
        padding: 10px;
        display: block;
        color: #000;
        text-decoration: none;
      }

      &-active {
        a {
          box-shadow: inset 0 -2px 0 0 $primary-color;
        }
      }
    }
  }

  &__panel {
    margin: 30px 0;
  }
}

.change-password {
  &__form {
    .text-input {
      width: 40%;
    }
  }
}

.user-info {
  &__form {
    display: flex;
    flex-wrap: wrap;

    .text-input {
      width: 25%;
    }
  }
}

.user-visit {
  margin-bottom: 50px;

  &__desc {
    list-style: none;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px;

    li {
      font-size: 16px;
      padding-bottom: 6px;
    }
  }
  
  &__form {
    display: flex;
    flex-wrap: wrap;

    .text-input {
      width: 25%;

      &.w-custom {
        width: 50% !important; 
      }
    }
  }

  &__lists {
    display: flex;
    margin-top: 30px;

    &_grid {
      max-height: 450px;
      height: 100%;
      overflow-y: auto;
      padding: 5px 10px;
      border: 1px solid #ccc;
      margin: 10px 5px;
      border-radius: 5px;
    }

    &_1 {
      width: 33.3333%;
    }

    &_2 {
      width: 33.3333%;
    }

    &_3 {
      width: 33.3333%;
    }

    &_items {
      list-style: none;
    }

    &_item {
      display: flex;
      justify-content: space-between;
      border: 1px solid #eee;
      margin: 8px 0;
      align-items: center;
      border-radius: 4px;
      height: 32px;
      padding: 0 5px;
    }

    &_btn {
      font-size: 16px;
      border: 1px solid $primary-color;
      background: none;
      color: $primary-color;
      padding: 0;
      border-radius: 4px;
      cursor: pointer;
      height: 24px;
      width: 24px;
      line-height: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .3s;

      &:hover {
        color: $white-color;
        background: $primary-color;
      }

      &:focus {
        outline: none;
      }
    }

    &_title {
      margin: 0 5px;
    }
  }
}
