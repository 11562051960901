.header {
  background: #2b3d51;
  color: $white-color;
  height: 70px;
  box-shadow: -2px -7px 17px 0px #000;
  position: relative;
  z-index: 99;
  min-width: 270px;

  &__toggle-menu {
    display: none;
    background: none;
    border: none;
    padding: 5px;

    svg {
      font-size: 30px;
      color: $white-color;
    }
    &:focus {
      outline: none;
    }
  }

  &__grid {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    align-items: center;
    height: 100%;
  }

  &__btns {
    display: flex;

    &_user {
      display: flex;
      align-items: center;
      color: #ccc;

      svg {
        font-size: 24px;
        margin-right: 10px;
        color: #ccc;
      }
    }

    &_logout {
      background: none;
      border: none;
      color: $red-color;
      margin-right: 10px;
      display: flex;
      cursor: pointer;
      padding: 10px;
      position: relative;

      &:focus {
        outline: none;
      }

      svg {
        font-size: 25px;
      }

      span {
        position: absolute;
        top: 75%;
        background: rgba($red-color, .6);
        padding: 2px 10px;
        left: 0;
        border-radius: 5px;
        color: $white-color;
        visibility: hidden;
        opacity: 0;
        transition: .3s;
      }

      &:hover span {
        top: 100%;
        visibility: visible;
        opacity: 1;
      }
    }
  }

  // &__nav {
  //   height: 100%;

  //   &_items {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     height: 100%;
  //     list-style: none;
  //   }

  //   &_item {
  //     a {
  //       color: #fff;
  //       text-decoration: none;
  //       padding: 15px;
  //     }

  //     &-active {
  //       a {
  //         color: #000;
  //       }
  //     }
  //   }

  //   &_logout {
  //     font-size: 13px;
  //     padding: 7px;
  //     margin-right: 30px;
  //     cursor: pointer;
  //   }
  // }
}
