.button {
  color: #fff;
  padding: 7px 10px;
  width: 15%;
  cursor: pointer;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  background: $primary-color;
  font-size: 17px;
  transition: all 0.3s;
  position: relative;
  
  &:hover {
    background: $primary-color-dark;
  }

  &:focus {
    outline: none;
  }
  &:active {
    box-shadow: 0 0 0 2px $primary-color-dark;
  }

  &:disabled {
    color: transparent;

    &::before {
      content: "";
      background: url(../loader2.svg);
      position: absolute;
      width: 34px;
      height: 34px;
      background-repeat: no-repeat;
      background-size: contain;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin-right: auto;
      margin-left: auto;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.button-container {
  width: 100%;
}
