.table {
	width: 100%;
	border-collapse: collapse;
	min-width: 100%;

	td,
	th {
		border: 1px solid #dddddd;
		text-align: left;
		padding: 8px;
		text-align: center;
	}

	tr:nth-child(even) {
		background-color: #eeeeee;
	}

	tr {
		transition: 0.2s;
		&:hover {
			background: #ccc;
		}
	}

	thead {
		background: #3d3d3d !important;
		color: #fff;

		tr {
			background: #3d3d3d !important;

			&:hover {
				background: #3d3d3d !important;
			}
		}
	}
}

.table-report {
	tbody {
		tr {
			cursor: pointer;
		}
	}
}

.app-table {
	&__delete-btn {
		color: #ef5350 !important;
		min-width: auto !important;

		svg {
			color: #ef5350 !important;
			font-size: 22px !important;
		}
	}

	&__tr-hover {
		cursor: pointer;

		&.selected {
			background: #eee;

			td {
				font-weight: 600 !important;
			}
		}
	}

	td {
		font-family: "Vazir", sans-serif !important;
	}
}
