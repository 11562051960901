.visit-page {
    
    &__head {
        background: #6f6f6f;
        height: 53px;
        color: #fff;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        .text-input {
            width: 25%;
        }
    }
}

.visit-list {
    margin-top: 30px;

    &__title {
        margin-bottom: 10px;
    }
}