.login-page,
.signup-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  &__title {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
    font-weight: 500;
  }

  &__box {
    width: 350px;
    min-height: 385px;
    background: rgba($black-color, 0.3);
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 15px;
    color: $white-color;
    padding-bottom: 10px;
    position: relative;
    padding-top: 18px;
    margin: 30px 0;

    &_footer {
      width: 100%;
      text-align: left;
      position: absolute;
      bottom: 5px;
      left: 32px;

      span {
        margin-bottom: 0px;
        display: block;
        font-size: 13px;
        color: #ccc;
      }
    }
  }

  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 13px;
    margin-bottom: 30px;

    &_btn {
      padding: 5px 6px;
      width: 100%;
      border: none;
      cursor: pointer;
      background: none;
      border-radius: 5px;
      position: relative;
      font-size: 17px;
      color: #fff;
      transition: all 0.3s;
      margin-top: 10px;
      border: solid 3px #0056b8;

      &:hover {
        background: #0056b8;
      }

      &:focus {
        outline: none;
      }
      &:active {
        box-shadow: 0 0 0 2px #0056b8;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        color: transparent;

        &::before {
          content: "";
          background: url(../loader2.svg);
          position: absolute;
          width: 34px;
          height: 34px;
          background-repeat: no-repeat;
          background-size: contain;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin-right: auto;
          margin-left: auto;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }

    .text-input {
      &__input {
        margin-left: 0;
        margin-right: 0;
        background: rgba($white-color, 1);
        border: none;
        color: $black-color;

        &:focus {
          outline: none;
          box-shadow: none;
        //   background: rgba($black-color, 0.5);
        }
      }

      &__label {
        margin-right: 0;
      }
    }

    &_links {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 10px;

      a {
        color: $white-color;
        text-decoration: none;
        transition: all 0.3s;
        font-size: 14px;

        &:hover {
          box-shadow: 0 1px 0 0 $white-color;
        }
      }

      button {
        background: none;
        cursor: pointer;
        color: $white-color;
        transition: all 0.3s;
        font-size: 14px;
		border: none;
        &:hover {
          box-shadow: 0 1px 0 0 $white-color;
        }
      }
    }

    .hLCrav .rnc-input {
      background: rgba($black-color, 0.3);
      border: none;
      color: $white-color;

      &:focus {
        outline: none;
        box-shadow: none;
        background: rgba($black-color, 0.5);
      }
    }

    .hLCrav .rnc {
      background-color: transparent;
    }
  }
}

.fast-visit-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  &__title {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
    font-weight: 500;
    color: $white-color;
  }

  h1 {
    color: $white-color;
  }

  &__desc {
    text-align: right;
    color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    line-height: 27px;

    span {
      font-weight: 100;
      font-size: 14px;
    }
  }

  &__box {
    width: 750px;
    min-height: 385px;
    background: rgba($black-color, 0.3);
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 15px;
    padding-bottom: 10px;
    position: relative;
    padding-top: 18px;
    margin: 30px 0;

    &_footer {
      width: 100%;
      text-align: left;
      position: absolute;
      bottom: 5px;
      left: 32px;

      span {
        margin-bottom: 0px;
        display: block;
        font-size: 13px;
        color: #ccc;
      }
    }
  }

  &__form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 13px;
    margin-bottom: 30px;

    &_btn {
      padding: 5px 6px;
      width: 30%;
      border: none;
      cursor: pointer;
      background: none;
      border-radius: 5px;
      position: relative;
      font-size: 17px;
      color: $white-color;
      transition: all 0.3s;
      margin-top: 10px;
      border: solid 3px #0056b8;

      @media (max-width: 500px) {
        width: 100%;
      }

      &:hover {
        background: #0056b8;
      }

      &:focus {
        outline: none;
      }

      &:active {
        box-shadow: 0 0 0 2px #0056b8;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        color: transparent;

        &::before {
          content: "";
          background: url(../loader2.svg);
          position: absolute;
          width: 34px;
          height: 34px;
          background-repeat: no-repeat;
          background-size: contain;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin-right: auto;
          margin-left: auto;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }

    .text-input {
      width: 48%;
      margin-bottom: 30px;

      @media (max-width: 500px) {
        width: 100%;
      }

      &__input {
        margin-left: 0;
        margin-right: 0;
        background: rgba($black-color, 0.3);
        border: none;
        color: $white-color;

        &:focus {
          outline: none;
          box-shadow: none;
          background: rgba($black-color, 0.5);
        }
      }

      &__textarea {
        margin-left: 0;
        margin-right: 0;
        background: rgba($black-color, 0.3);
        border: none;
        color: $white-color;

        &:focus {
          outline: none;
          box-shadow: none;
          background: rgba($black-color, 0.5);
        }
      }

      &__label {
        margin-right: 0;
      }

      .css-2613qy-menu * {
        color: red !important;
      }

      .css-2b097c-container .css-yk16xz-control {
        background-color: rgba($black-color, 0.3) !important;
        border-width: 1 !important;
        border-color: transparent !important;
      }

      .css-2b097c-container {
        margin-left: 0 !important;
        margin-right: 0 !important;
        font-size: 16px;
        height: 40px !important;
        // z-index: 0;
      }

      .css-yk16xz-control .css-1uccc91-singleValue {
        color: $white-color;
      }
    }

    &_links {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 10px;

      a {
        color: $white-color;
        text-decoration: none;
        transition: all 0.3s;
        font-size: 14px;

        &:hover {
          box-shadow: 0 1px 0 0 $white-color;
        }
      }
    }

    .hLCrav .rnc-input {
      background: rgba($black-color, 0.3);
      border: none;
      color: $white-color;

      &:focus {
        outline: none;
        box-shadow: none;
        background: rgba($black-color, 0.5);
      }
    }

    .hLCrav .rnc {
      background-color: transparent;
    }

    .hLCrav .rnc {
      padding: 0 !important;
      margin-bottom: 10px;
    }
  }
}

.error-message {
  font-size: 13px;
  padding-top: 5px;
  color: #ef5350;
}
