.text-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  // z-index: 3;

  &__input {
    max-width: 100%;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid hsl(0,0%,80%);
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    height: 40px;
    text-align: left;

    &:focus {
      outline-color: #2484ff;
    }

    &:hover {
      border-color: hsl(0,0%,70%);
    }
  }

  &__checkbox {
    
    &_label {
      display: block;
      position: relative;
      padding-right: 35px;
      margin-bottom: 20px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-size: 16px;
    }
    &_checkmark {
      position: absolute;
      top: 0;
      right: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;
      border-radius: 5px;

      &::after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &_input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    &_input:checked ~ &_checkmark {
      background-color: $primary-color;

      &::after {
        display: block;
      }
    }

    &_input:checked ~ &_checkmark:after {
      display: block;
    }
  }

  .DatePicker__input {
    max-width: 100%;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid hsl(0,0%,80%);
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    height: 40px;
    width: 84%;

    &:focus {
      outline-color: #2484ff;
    }

    &:hover {
      border-color: hsl(0,0%,70%);
    }
  }

  &__textarea {
    max-width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid hsl(0, 0%, 70%);
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    font-family: "Vazir", sans-serif;

    &:focus {
      outline-color: #2484ff;
    }
  }

  .css-2b097c-container {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    height: 40px !important;

    .css-yk16xz-control {
      min-height: 42px !important;
    }
  }

  &__label {
    font-size: 16px;
    padding-bottom: 6px;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__icon {
    position: absolute;
    top: 10px;
    left: 20px;

    svg {
      font-size: 22px;
    }
  }

  &__show-pass {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    svg {
      color: #000;
      font-size: 22px;
    }
  }
}
